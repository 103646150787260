import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Aside from "../component/Aside";
import Header from "../component/Header";
import { urlll } from "../api/Apis";

function Editcategories() {
  const { id } = useParams();

  const [seeResponse, setResponse] = useState();

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    handlepolicies();
  }, []);

  const [itemCategories, setItemCategories] = useState([]); // State to store item categories fetched from the backend

  const [formData, setFormData] = useState({
    name: "",
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    // Handle the file input separately to store the selected image file

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlepolicies = async () => {
    await axios
      .get(`${urlll}/viewcategory/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Process the successful response here

        console.log(response.data)
        setFormData({
          name: response.data.name,
        });
      })
      .catch((error) => {
        // Handle error responses here

        console.error("Error:", error.message);
      });
  };

  const handleUpdateProfile = async () => {
    // Perform update profile logic here (e.g., call API to update the profile)
    // The 'profile' state contains the updated user profile data
    // console.log("Updated profile:", profile);
    setisLoading(true);

    console.log(formData);

    const dataObject = {
      name: formData.name,
    };

    await axios
      .patch(`${urlll}/updatecat/${id}`, dataObject)
      .then((response) => {
        // Process the successful response here
        setResponse(response.data);
        console.log(response.data);
        setisLoading(false);
      })
      .catch((error) => {
        // Handle error responses here

        console.error("Error:", error.message);
        setisLoading(false);
      });
  };

  return (
    <div className="flex w-full">
      <Aside />

      <div className=" w-full bg-gray-100 h-full">
        <Header />

        <div className="w-full h-screen  p-8 shadow-lg rounded-lg mt-[70px] ">
          {seeResponse && (
            <h2 className="text-green-500 font-[800] text-lg pb-[40px]">
              {" "}
              UPDATED SUCCESSFULLY
            </h2>
          )}
          {/* 
          {formData.image && <img src={formData.image} alt="Selected Image" />} */}

          <div className="grid grid-cols-3 gap-3  w-full ">
            <div className="mb-4">
              <label
                className="block font-bold mb-2 text-[13px]"
                htmlFor="name"
              >
                CATEGORY NAME
              </label>
              <input
                className="w-full p-2 border-[#37B6D4]  border-b-2 rounded-md"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

         

          </div>
          {isLoading ? (
            <h3> Loading....</h3>
          ) : (
            <button
              className="px-4 py-2 border border-white  mt-[30px] bg-[#37B6D4] text-black w-full rounded-[5px]"
              onClick={handleUpdateProfile}
            >
              UPDATE PRODUCTS
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Editcategories;
