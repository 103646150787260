import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Aside from "../component/Aside";
import Header from "../component/Header";
import { urlll } from "../api/Apis";

function Editproduct() {
  const { id } = useParams();

  const [seeResponse, setResponse] = useState();

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    handlepolicies();
    fetchItemCategories();
  }, []);

  const [itemCategories, setItemCategories] = useState([]); // State to store item categories fetched from the backend

  const fetchItemCategories = () => {
    setisLoading(true);
    // Replace 'your-backend-endpoint-for-item-categories' with the actual API endpoint
    fetch(`${urlll}/viewcategory`)
      .then((response) => response.json())
      .then((data) => {
        setItemCategories(data);
        setisLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching item categories: " + error);
        setisLoading(false);
      });
  };

  const [formData, setFormData] = useState({
    itemname: "",
    itemdescription: "",
    itemcount: "",
    itemprice: "",
    itemcategory: "",
    image: null, // Store the selected image file
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    // Handle the file input separately to store the selected image file
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handlepolicies = async () => {
    await axios
      .get(`${urlll}/product/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Process the successful response here
        setFormData({
          itemname: response.data.itemname,
          itemdescription: response.data.itemdescription,
          itemcount: response.data.itemcount,
          itemprice: response.data.itemprice,
          itemcategory: response.data.category,
        });
      })
      .catch((error) => {
        // Handle error responses here

        console.error("Error:", error.message);
      });
  };

  const handleUpdateProfile = async () => {
    // Perform update profile logic here (e.g., call API to update the profile)
    // The 'profile' state contains the updated user profile data
    // console.log("Updated profile:", profile);
    setisLoading(true);

    console.log(formData);

    const formDataToSend = new FormData();

    formDataToSend.append("_method", 'patch');
    formDataToSend.append("itemname", formData.itemname);
    formDataToSend.append("itemdescription", formData.itemdescription);
    formDataToSend.append("itemcount", formData.itemcount);
    formDataToSend.append("itemprice", formData.itemprice);
    formDataToSend.append("category", formData.itemcategory);
    formDataToSend.append("image", formData.image);

    const dataObject = {
       
      itemname: formData.itemname,
      itemdescription: formData.itemdescription,
      itemcount: formData.itemcount,
      itemprice: formData.itemprice,
      category: formData.itemcategory,
      image: formData.image,
    };

    console.log(formData.image);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'enctype' : 'multipart/form-data',
      },
    };

    if (formData.image != "") {
      console.log("yes it is");
      await axios
        .post(
          `${urlll}/product/${id}`,
          formDataToSend,config
        )
        .then((response) => {
          // Process the successful response here
          setResponse(response.data);
          console.log(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          // Handle error responses here

          console.error("Error:", error.message);
          setisLoading(false);
        });
    } else {
      await axios
        .patch(
          `http://localhost/patabaapi/public/api/product/${id}`,
          dataObject
        )
        .then((response) => {
          // Process the successful response here
          setResponse(response.data);
          console.log(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          // Handle error responses here

          console.error("Error:", error.message);
          setisLoading(false);
        });
    }

    console.log(dataObject);
  };

  return (
    <div className="flex w-full">
      <Aside />

      <div className=" w-full bg-gray-100 h-full">
        <Header />

        <div className="w-full h-screen  p-8 shadow-lg rounded-lg mt-[70px] ">
          {seeResponse && (
            <h2 className="text-green-500 font-[800] text-lg pb-[40px]">
              {" "}
              UPDATED SUCCESSFULLY
            </h2>
          )}
          {/* 
          {formData.image && <img src={formData.image} alt="Selected Image" />} */}

          <div className="grid md:grid-cols-3   grid-cols-1 gap-3  w-full ">
            <div className="mb-4">
              <label
                className="block font-bold mb-2 text-[13px]"
                htmlFor="name"
              >
                ITEM NAME
              </label>
              <input
                className="w-full p-2 border-[#37B6D4]  border-b-2 rounded-md"
                type="text"
                name="insurer"
                value={formData.itemname}
                onChange={handleChange}
                readOnly
              />
            </div>

            <div className="mb-4">
              <label
                className="block font-bold mb-2 text-[13px]"
                htmlFor="email"
              >
                ITEM DESCRIPTION
              </label>
              <input
                className="w-full p-2 border-[#37B6D4] border-b-2 rounded-md"
                type="text"
                name="itemdescription"
                value={formData.itemdescription}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className="mb-4">
              <label
                className="block font-bold mb-2 text-[13px]"
                htmlFor="email"
              >
                ITEM QUANTITY
              </label>
              <input
                className="w-full p-2 border-[#37B6D4]  border-b-2 rounded-md"
                type="text"
                name="itemcount"
                value={formData.itemcount}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className="mb-4">
              <label
                className="block font-bold mb-2 text-[13px]"
                htmlFor="email"
              >
                ITEM PRICE
              </label>
              <input
                className="w-full p-2 border-[#37B6D4]  border-b-2 rounded-md"
                type="text"
                name="itemprice"
                value={formData.itemprice}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className="mb-4 pb-[20px]">
              <label htmlFor="itemcategory" className="block mb-1 font-[800]">
                Item Category
              </label>
              <select
                id="itemcategory"
                name="itemcategory"
                value={formData.itemcategory}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded shadow-md"
                
        >
                <option value="">Select Item Category</option>
                <option value={formData.itemcategory}>
                  {formData.itemcategory}
                </option>
                {isLoading ? (
                  <div> Loading </div>
                ) : (
                  <>
                    {itemCategories?.map((category) => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>

            <div className="mb-4 pb-[20px]">
              <label htmlFor="image" className="block mb-1 font-[800]">
                Image
              </label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleChange}
                accept="image/*" // Limit file type to images
                className="w-full px-4 py-2 border rounded shadow-md"
              />
            </div>
          </div>
          {isLoading ? (
            <h3> Loading....</h3>
          ) : (
            <button
              className="px-4 py-2 border border-white  mt-[30px] bg-[#37B6D4] text-black w-full rounded-[5px]"
              onClick={handleUpdateProfile}
            >
              UPDATE PRODUCTS
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Editproduct;
