import React from "react";
import Header from "../component/Header";
import Aside from "../component/Aside";
import Productform from "../component/Productform";

const Product = () => {
  return (
    <div className="w-full">
      <Header />
      <div className=" w-[100%] flex">
        <Aside />

        <Productform />
      </div>
    </div>
  );
};

export default Product;
