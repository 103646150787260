import React, { useState, useEffect } from "react";
import { urlll } from "../api/Apis";

const Categoryform = () => {
  const [formData, setFormData] = useState({
    categoryname: "",
  });
  const [itemCategories, setItemCategories] = useState([]); // State to store item categories fetched from the backend
  const [isLoading, setisloading] = useState(false);
  const [isSuccess, setSuccess] = useState("");
  const [isError, setIserror] = useState("");

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    // Handle the file input separately to store the selected image file

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setisloading(true);
    // Create a FormData object to send the form data, including the image
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.categoryname);

    // Send the formDataToSend to the backend
    fetch(`${urlll}/product/category`, {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => {
        if (response.ok) {
          // Handle success (e.g., show a success message)
          console.log("Data sent successfully!");

          setisloading(false);
          setSuccess("Category Uploaded Successfully");
        } else {
          // Handle errors (e.g., show an error message)
          console.error("Failed to send data to the backend.");
          setIserror("Error Uploading products");
        }
      })
      .catch((error) => {
        console.error("Error sending data to the backend: " + error);
        setIserror("Error Uploading products" + error);
      });
  };

  return (
    <div className="w-[100%] grid self-center place-items-center py-[100px]">
      <h2>ADD CATEGORY</h2>

      <form className=" h-[600px] self-center w-[50%]">
        {isError ? <h2> {isError} </h2> : null}
        {isSuccess ? <h2> {isSuccess} </h2> : null}
        <div className="mb-4 pb-[20px]">
          <label htmlFor="categoryname" className="block mb-1 font-[800]">
        
            CATEGORY NAME
          </label>
          <input
            type="text"
            id="categoryname"
            name="categoryname"
            value={formData.categoryname}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-md"
            placeholder="Category Name"
          />
        </div>

        {!isLoading ? (
          <button
            type="submit"
            className="bg-[#37B6D4] text-white px-4 py-2 rounded w-full"
            onClick={handleSubmit}
          >
            SUBMIT
          </button>
        ) : (
          <h3>Loading...</h3>
        )}
      </form>
    </div>
  );
};

export default Categoryform;
