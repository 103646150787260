import React, { useState, useEffect } from "react";
import { urlll } from "../api/Apis";

const Productform = () => {
  const [formData, setFormData] = useState({
    itemname: "",
    itemdescription: "",
    itemcount: "",
    itemprice: "",
    itemcategory: "",
    image: null, // Store the selected image file
  });
  const [itemCategories, setItemCategories] = useState([]); // State to store item categories fetched from the backend
  const [isLoading, setisloading] = useState(false);
  const [isSuccess, setSuccess] = useState("");
  const [isError, setIserror] = useState("");

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    // Handle the file input separately to store the selected image file
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    // Fetch item categories from the backend and populate the itemCategories state
    fetchItemCategories();
  }, []);

  console.log(itemCategories);
  const fetchItemCategories = () => {
    setisloading(true);
    // Replace 'your-backend-endpoint-for-item-categories' with the actual API endpoint
    fetch(`${urlll}/viewcategory`)
      .then((response) => response.json())
      .then((data) => {
        setItemCategories(data);
        setisloading(false);
    
      })
      .catch((error) => {
        console.error("Error fetching item categories: " + error);
        setisloading(false);
  
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setisloading(true);
    // Create a FormData object to send the form data, including the image
    const formDataToSend = new FormData();
    formDataToSend.append("itemname", formData.itemname);
    formDataToSend.append("itemdescription", formData.itemdescription);
    formDataToSend.append("itemcount", formData.itemcount);
    formDataToSend.append("itemprice", formData.itemprice);
    formDataToSend.append("category", formData.itemcategory);
    formDataToSend.append("image", formData.image);

    // Send the formDataToSend to the backend
    fetch(`${urlll}/product`, {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => {
        if (response.ok) {
          // Handle success (e.g., show a success message)
          console.log("Data sent successfully!");

          setisloading(false);
          setSuccess("Productts Uploaded Successfully");
        } else {
          // Handle errors (e.g., show an error message)
          console.error("Failed to send data to the backend.");
          setIserror("Error Uploading products");
        }
      })
      .catch((error) => {
        console.error("Error sending data to the backend: " + error);
        setIserror("Error Uploading products" + error);
      });
  };

  return (
    <div className="w-[100%] grid self-center place-items-center py-[100px]">
      <div className="pb-[30px]">
      <h2>ADD PRODUCT</h2>
      </div>
   

      <form className="w-full px-4">
        {isError ? <h2> {isError} </h2> : null}
        {isSuccess ? <h2> {isSuccess} </h2> : null}

        <div className="grid md:grid-cols-3 grid-cols-1 gap-3  w-full px-4">

  
        <div className="mb-4 pb-[20px]">
          <label htmlFor="itemname" className="block mb-1 font-[800]">
            ITEM NAME
          </label>
          <input
            type="text"
            id="itemname"
            name="itemname"
            value={formData.itemname}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded shadow-md border-[#37B6D4]  border-b-2 "
            placeholder="Item Name"
          />
        </div>
        <div className="mb-4 pb-[20px]">
          <label htmlFor="itemdescription" className="block mb-1 font-[800]">
      
            ITEM DESCRIPTION
          </label>
          <input
            type="text"
            id="itemdescription"
            name="itemdescription"
            value={formData.itemdescription}
            onChange={handleChange}
            className="w-full px-4 py-2  rounded shadow-md border-[#37B6D4]  border-b-2"
            placeholder="Item Description"
          />
        </div>

        <div className="mb-4 pb-[20px]">
          <label htmlFor="itemname" className="block mb-1 font-[800]">
            ITEM COUNT
          </label>
          <input
            type="number"
            id="itemcount"
            name="itemcount"
            value={formData.itemcount}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded shadow-md border-[#37B6D4]  border-b-2"
            placeholder="Quantity"
          />
        </div>

        <div className="mb-4 pb-[20px]">
          <label htmlFor="itemname" className="block mb-1 font-[800]">
           
            ITEM PRICE
          </label>
          <input
            type="number"
            id="itemprice"
            name="itemprice"
            value={formData.itemprice}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded shadow-md border-[#37B6D4]  border-b-2"
            placeholder="Item Price"
          />
        </div>

        <div className="mb-4 pb-[20px]">
          <label htmlFor="itemcategory" className="block mb-1 font-[800]">
            
            ITEM CATEGORY
          </label>
          <select
            id="itemcategory"
            name="itemcategory"
            value={formData.itemcategory}
            onChange={handleChange}
            className="w-full px-4 py-2  rounded shadow-md border-[#37B6D4]  border-b-2"
          >
            <option value="">Select Item Category</option>
            {isLoading ? (
              <div> Loading </div>
            ) : (
              <>
                {itemCategories?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>

        <div className="mb-4 pb-[20px]">
          <label htmlFor="image" className="block mb-1 font-[800]">
            IMAGE
          </label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleChange}
            accept="image/*" // Limit file type to images
            className="w-full px-4 py-2  rounded shadow-md border-[#37B6D4]  border-b-2"
          />
        </div>

        </div>


        {!isLoading ? (
          <button
            type="submit"
            className="bg-[#37B6D4] text-white px-4 py-2 rounded w-full"
            onClick={handleSubmit}
          >
            SUBMIT
          </button>
        ) : (
          <h3>Loading...</h3>
        )}
      </form>
    </div>
  );
};

export default Productform;
