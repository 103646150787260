import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { postItem } from "../api/Apis";
import { CartContext } from "../context/cart/CartState";
import Header from "../component/Header";
import Navbar from "../component/Navbar";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const { addToken, accessTokens } = useContext(CartContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  console.log(accessTokens);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [data, setData] = useState({});
  console.log(data);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with the form data
    console.log(formData);

    setIsLoading(true);

    const formDatanew = new FormData();
    formDatanew.append("email", formData.email);
    formDatanew.append("password", formData.password);

    postItem(formDatanew)
      .then((items) => {
        // Do something with the items data
        console.log(items);
        setData(items);

        const userdata = {
          token: items.access_token,
          userid: items.user.id,
          fullname: items.user.name,
        };

        addToken(userdata);
        setIsLoading(false);
        navigate("/dashboard");
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
        setError("Invalid Email or Password");
        setIsLoading(false);
      });
  };
  return (
    <>
      <Navbar />
      <div className="flex w-full gap-2 mt-[50px] justify-center  items-center pb-[60px]">
        <div className=" md:w-[50%] w-full px-10 pt-[10px]  ">
          <div className=" pb-[30px]">
            <h2 className="text-center text-[40px] font-[800]">
              {" "}
              Login to Admin
            </h2>
          </div>

          {error ? <h3 className="text-red-500"> {error}</h3> : null}
          <form
            className="w-full rounded-[10px] border-2 px-[30px] py-[30px] shadow-md"
            onSubmit={handleSubmit}
          >
            <div className="mb-4 pb-[20px]">
              <label htmlFor="email" className="block mb-1 font-[800]">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded shadow-md"
                placeholder="Email address"
              />
            </div>
            <div className="mb-4  pb-[20px]">
              <label htmlFor="password" className="block mb-1 font-[800]">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded shadow-md"
                placeholder="Password"
              />
            </div>

            <button
              type="submit"
              className="bg-[#000] hover:bg-[#000] text-white px-4 py-2 rounded-[5px] w-full  shadow-md"
            >
              {isLoading ? <p>Loading... </p> : <p>Submit</p>}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
