import React, { useEffect, useState } from "react";
import axios from "axios";
// import { url } from "../api/Api";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import Aside from "../component/Aside";
import Header from "../component/Header";
import { urlll } from "../api/Apis";

export default function Viewcategories() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);


  const [seeResponse, setResponse] = useState("");

  const [isLoading, setisLoading] = useState(false);
  console.log(products);
  useEffect(() => {
    handlepolicies();
  }, []);
  const handlepolicies = async () => {
    setisLoading(true);
    await axios
      .get(`${urlll}/viewcategory`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Process the successful response here
        setProducts(response.data);

        setisLoading(false);
      })
      .catch((error) => {
        // Handle error responses here

        console.error("Error:", error.message);
        setisLoading(false);
      });
  };

  const handleEdit = (row) => {
    // Handle the edit action here (e.g., navigate to edit page)
    console.log("Edit button clicked for row:", row);
    navigate(`/editcategory/${row}`);
  };

  const handleDelete = async (row) => {
    // Handle the delete action here (e.g., show confirmation modal or call API to delete)

    await axios
      .delete(`${urlll}/deletecategory/${row}`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        // Process the successful response here
        setResponse("Deleted successfully");
        handlepolicies();
      })
      .catch((error) => {
        // Handle error responses here

        console.error("Error:", error.message);
      });
  };

  // const handleDeactivate = async (row) => {
  //   // Handle the delete action here (e.g., show confirmation modal or call API to delete)
  //   const userid2 = await JSON.parse(localStorage.getItem("userdata"));
  //   console.log(userid2.token);
  //   await axios
  //     .patch(`${url}/products/deactivateproduct/${row}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userid2.token}`,
  //       },
  //     })
  //     .then((response) => {
  //       // Process the successful response here
  //       setResponse(response.data);
  //       handlepolicies();
  //     })
  //     .catch((error) => {
  //       // Handle error responses here

  //       console.error("Error:", error.message);
  //     });
  // };

 



  const columns = [
    {
      name: "ACTIONS",
      cell: (row) => (
        <div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded w-[100px]"
            onClick={() => handleEdit(row.id)}
          >
            Edit
          </button>
          <button
            className=" w-[100px]  bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </button>
        
        </div>
      ),
      ignoreRowClick: true, // This ensures that clicking the buttons won't trigger row click events
      allowOverflow: true, // Allow the content to overflow the cell
      button: true, // Render the content as buttons
    },


    {
      name: "ITEM NAME",
      selector: "name",
      sortable: true,
    },

  ];

  const data = [
    products,
    // Add more data as needed
  ];
  const [searchText, setSearchText] = useState("");


//   console.log(products);
  const filteredData = Array.isArray(products)
    ? products.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : data.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );

  const handleExportToExcel = () => {
    const csvData = filteredData.map((item) => ({
      INSURER: item.insurer,
      PRODUCT_TYPE: item.producttype,
      POLICY_NAME: item.policyname,
      POLICY_DESCRIPTION: item.policydescription,
      PREMIUM_CALE: item.premiumcale,
      COMMISSION_POLICY: item.commissiononpolicy,
      CURRENT_TAT: item.currenttat,
    }));

    const blob = new Blob([parseDataToCSV(csvData)], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, "data.csv");
  };

  const parseDataToCSV = (data) => {
    const header = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((row) => Object.values(row).join(",") + "\n");
    return header + rows.join("");
  };

  return (
    <div className="flex w-full">
      <Aside/>

      <div className=" w-full bg-gray-100 h-screen">
        <Header />
        <div className="w-full h-full border-b-2   border-black grid pt-[50px] px-[30px]">
          <div className="overflow-x-auto ">
           
            {seeResponse && (
              <h2 className="text-red-500 font-[800] text-lg pb-[40px]">
                {" "}
                {seeResponse}
              </h2>
            )}

            {isLoading ? (
              <div className="flex justify-center items-center w-full h-full">
                <h3 className="text-green-500 text-[50px]">LOADING......</h3>
              </div>
            ) : (
              <>
                {products ? (
                  <>
                    {Array.isArray(products) ? (
                      <DataTable
                        title="CATEGORIES LIST"
                        columns={columns}
                        data={products}
                        pagination
                        selectableRows
                        responsive={true}
                        striped={true}
                        className="space-x-4"
                        customStyles={{
                          table: {
                            // Set a fixed width for the table on larger screens
                            width: "100%",
                            // Add tailwind CSS classes for smaller screen breakpoints
                            "@media screen and (max-width: 768px)": {
                              width: "auto",
                            },
                          },
                        }}
                      />
                    ) : (
                      <DataTable
                        title="CATEGORIES LIST"
                        columns={columns}
                        data={data}
                        pagination
                        selectableRows
                        responsive={true}
                        striped={true}
                        className="space-x-4"
                        customStyles={{
                          table: {
                            // Set a fixed width for the table on larger screens
                            width: "100%",
                            // Add tailwind CSS classes for smaller screen breakpoints
                            "@media screen and (max-width: 768px)": {
                              width: "auto",
                            },
                          },
                        }}
                      />
                    )}
                
                  </>
                ) : (
                  <h2> Loading ...</h2>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}