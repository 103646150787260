import React from "react";
import Header from "../component/Header";
import Aside from "../component/Aside";
import Categoryform from "../component/Categoryform";

const Addcategories = () => {
  return (
    <div className="w-full">
      <Header />
      <div className=" w-[100%] flex">
        <Aside />

        <Categoryform />
      </div>
    </div>
  );
};

export default Addcategories;
