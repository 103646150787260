import Logo from "../img/logo.png";
import React from "react";
// import { CartContext } from "../context/cart/CartState";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [navToggle, setNavToggle] = React.useState(false);

  const handleNavToggle = () => {
    setNavToggle(!navToggle);
  };

  //   const { cartItems } = useContext(CartContext);

  //   console.log(cartItems.length);

  return (
    <div className="bg-[#37B6D4] w-full md:h-120  h-[150px]">
      <div className="flex h-24 bg-[#37B6D4] w-full justify-between">
        <div className="flex px-[24px] py-[24px] w-[50%]">
          <Link to="/">
            <img src={Logo} alt="logo" className="h-[25px] w-[120px]" />
          </Link>

          <div className="pl-[24px] mt-[-5px] md:flex hidden ">
       
       
          </div>
        </div>


    </div>
    </div>
  );
};

export default Navbar;
