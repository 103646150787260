import React, { createContext, useState, useEffect } from "react";

// Create the Cart context
export const CartContext = createContext();

// Create the Cart provider
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    return JSON.parse(localStorage.getItem("cartItems")) || [];
  });

  const [accessTokens, setAccessTokens] = useState(() => {
    const accessToken = localStorage.getItem("accessToken1");
    if (accessToken) {
      return JSON.parse(accessToken);
    } else {
      return {
        token: "",
        userid: "",
        email: "",
      };
    }
  });

  useEffect(() => {
    localStorage.setItem("accessToken1", JSON.stringify(accessTokens));
  }, [accessTokens]);

  useEffect(() => {
    // Save cart items to localStorage
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item) => {
    console.log(item.productid);

    const filteredData = cartItems.filter((items) => items.productid === item.productid);

    if (filteredData.length < 1) {
      setCartItems([...cartItems, item]);
      alert("Added To Cart");
    } else {
      setCartItems(
        cartItems.filter((prevItems) =>
          prevItems.id === item.productid
            ? (prevItems.quantity += item.quantity)
            : prevItems.quantity
        )
      );
      alert("Added To Cart");
    }
  };

  const removeFromCart = (id) => {
    setCartItems((prevItems) =>
      prevItems.filter((cartItem) => cartItem.productid !== id)
    );
  };

  const reduceCartQuantity = (id) => {
    setCartItems((prevItems) =>
      prevItems.filter((cartItem) =>
        cartItem.productid === id && cartItem.quantity > 1
          ? (cartItem.quantity -= 1)
          : cartItem.quantity
      )
    );
  };

  const increaseCartQuantity = (id) => {
    setCartItems((prevItems) =>
      prevItems.filter((cartItem) =>
        cartItem.productid === id ? (cartItem.quantity += 1) : cartItem.quantity
      )
    );
  };

  const addToken = (token) => {
    setAccessTokens(token);
  };
  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        accessTokens,
        addToken,
        addToCart,
        addToken,
        removeFromCart,
        clearCart,
        reduceCartQuantity,
        increaseCartQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
