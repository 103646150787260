import { Link } from "react-router-dom";

const Aside = () => {
  return (
    <div className="border-[2px] w-[15%] h-screen hidden md:grid bg-[#37B6D4]">
      <ul className="w-[100%] px-4 py-4 mt-[50px]">
        <Link to="/dashboard">
          <li className="px-2 py-2 text-[16px]">Home</li>
        </Link>

        <li className="px-2 py-2 font-[800]">Product</li>
        <Link to="/addproduct" className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <li className="px-4 py-2 text-[10px] text-[#fff]">Add Products</li>
        </Link>
        <Link to="/viewproduct" className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>

          <li className="px-4 py-2 text-[10px] text-[#fff]">View Products</li>
        </Link>
        <li className="px-2 py-2 font-[800]">Categories</li>
        <Link to="/addcategory" className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <li className="px-4 py-2 text-[10px] text-[#fff]">Add Categories</li>
        </Link>
        <Link to="/viewcategory" className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>

          <li className="px-4 py-2 text-[10px] text-[#fff]">View Categories</li>
        </Link>
      </ul>
      <Link to="/" className="mt-[-150px] px-4 py-4">
        <ul className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
            />
          </svg>

          <li className="px-2 py-2 font-[800]">Logout</li>
        </ul>
      </Link>
    </div>
  );
};

export default Aside;
