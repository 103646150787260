import React from "react";
import Header from "../component/Header";
import Aside from "../component/Aside";
import Categoryform from "../component/Categoryform";
import { Link } from "react-router-dom";

const Dashboard = () => {

  
  return (
    <div className="w-full">
      <Header />

      <div className=" w-[100%] flex">
        <Aside />

        <div className="w-[100%] grid md:grid-cols-2 grid-cols-1 gap-2">
          <div className="w-full py-[50px] px-[50px]">
            <Link to="/vieworders">
              <div className="bg-[#37B6D4]  border-black h-[200px] md:w-[80%] w-[100%] rounded shadow-lg grid place-content-center  place-items-center">
                <h3 className="font-[900]">RECENT ORDERS</h3>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-12 h-12 mt-[10px] text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                  />
                </svg>
              </div>
            </Link>
          </div>

          <div className="w-full py-[50px] px-[50px]">
            <Link to="/viewproduct">
              <div className="border-[2px] bg-[#37B6D4] h-[200px] md:w-[80%] w-[100%] rounded shadow-lg grid place-content-center  place-items-center">
                <h3 className="font-[900]">PRODUCTS</h3>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-12 h-12 mt-[10px] text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"
                  />
                </svg>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
