const apikey = "Bearer veaxLaEE5uG57slAMSBA-Rc3";

const url = "https://api.patabah.com/api";
export const urlll = "https://api.patabah.com/api";

// const url = "http://localhost/patabaapi/public/api";

// export const urlll = "http://localhost/patabaapi/public/api";


const options = {
  headers: {
    "Content-Type": "application/json"
  },
};

const options2 = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const getItems = async () => {
  return await fetch(`${url}/product`, options).then((response) =>
    response.json()
  );
};

export const getsingleItems = async (id) => {
  return await fetch(`${url}/product/${id}`, options).then((response) =>
    response.json()
  );
};



export const getsingleItemscat = async (category) => {
  return await fetch(`${url}/product/category/${category}`, options).then((response) =>
    response.json()
  );
};

export const postItem = async (item) => {
  return await fetch(`${url}/auth/login`, {
    method: "POST",
    options2,
    body: item,
  }).then((response) => response.json());
};

export const postSingup = async (item) => {
  return await fetch(`${url}/auth/register`, {
    method: "POST",
    options2,
    body: item,
  }).then((response) => response.json());
};






export const postSales= async (item) => {

  const jsonString = JSON.stringify(item);

const requestOptions = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: jsonString,
};



  return await fetch(`${url}/savedata`,requestOptions)
               .then((response) => response.json());
};


