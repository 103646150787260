import Logo from "../img/logo.png";
import React from "react";
// import { CartContext } from "../context/cart/CartState";
import { Link } from "react-router-dom";

const Header = () => {
  const [navToggle, setNavToggle] = React.useState(false);

  const handleNavToggle = () => {
    setNavToggle(!navToggle);
  };

  //   const { cartItems } = useContext(CartContext);

  //   console.log(cartItems.length);

  return (
    <div className="bg-[#37B6D4] w-full md:h-120  h-[150px]">
      <div className="flex h-24 bg-[#37B6D4] w-full justify-between">
        <div className="flex px-[24px] py-[24px] w-[50%]">
          <Link to="/dashboard">
            <img src={Logo} alt="logo" className="h-[25px] w-[120px]" />
          </Link>

       
        </div>

        <div className="px-[24px] py-[24px] text-[#ffffff] flex justify-between">
          {!navToggle && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6 ml-2 mt-2 text-[#fff] cursor-pointer  md:hidden "
              onClick={() => {
                handleNavToggle();
              }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          )}
        </div>
      </div>

      {navToggle ? (
        <ul className="relative  rounded-[5px] z-10  bg-[#fff] w-[200px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 mt-[-10px] text-[#000] mr-[-10px] float-right  cursor-pointer"
            onClick={() => {
              handleNavToggle();
            }}
          >
            <path
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              clipRule="evenodd"
            />
          </svg>

          <ul className="w-[100%] px-4 py-4 mt-[50px]  md:hidden grid">
            <Link to="/">
              <li className="px-2 py-2 text-[16px]">Home</li>
            </Link>

            <li className="px-2 py-2 font-[800]">Product</li>
            <Link to="/addproduct" className="flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <li className="px-4 py-2 text-[10px]">Add Products</li>
            </Link>
            <Link
              to="/viewproduct"
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <li className="px-4 py-2 text-[10px]">View Products</li>
            </Link>
            <li className="px-2 py-2 font-[800]">Categories</li>
            <Link
              to="/addcategory"
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <li className="px-4 py-2 text-[10px]">Add Categories</li>
            </Link>
            <Link
              to="/viewcategory"
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <li className="px-4 py-2 text-[10px]">View Categories</li>
            </Link>
          </ul>
          <Link to="/" className="mt-[-150px] px-4 py-4">
        <ul className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
            />
          </svg>

          <li className="px-2 py-2 font-[800]">Logout</li>
        </ul>
      </Link>
        </ul>
      ) : null}

      
    </div>
  );
};

export default Header;
