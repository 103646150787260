import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./screen/Dashboard";
import Product from "./screen/Product";
import Viewproduct from "./screen/Viewproduct";
import Editproduct from "./screen/Editproduct";
import Addcategories from "./screen/Addcategories";
import Viewcategories from "./screen/Viewcategories";
import Editcategories from "./screen/Editcategories";
import Vieworders from "./screen/Vieworders";
import Viewsingleorder from "./screen/Viewsingleorder";
import Login from "./screen/Login";
import { CartProvider } from "./context/cart/CartState";

function App() {
  return (
    <Router>
      <CartProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/addproduct" element={<Product />} />
          <Route path="/viewproduct" element={<Viewproduct />} />
          <Route path="/editproduct/:id" element={<Editproduct />} />
          <Route path="/addcategory" element={<Addcategories />} />
          <Route path="/viewcategory" element={<Viewcategories />} />
          <Route path="/editcategory/:id" element={<Editcategories />} />
          <Route path="/vieworders" element={<Vieworders />} />
          <Route path="/viewsingleorder/:id" element={<Viewsingleorder />} />
        </Routes>
      </CartProvider>
    </Router>
  );
}

export default App;
